<template>
  <v-dialog persistent v-model="showNewInferenceVersionDialog" width="700">
    <v-card :loading="loading">
      <v-form
        lazy-validation
        v-model="form"
        ref="newInferenceEngineVersionForm"
        :disable="loading"
      >
        <v-card-title>
          <span class="text-h6">New Inference Engine Version</span>
          <v-spacer></v-spacer>
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row no-gutters class="mt-0">
            <v-col cols="12">
              <v-text-field
                v-model="inferenceVersionPayload.name"
                :rules="[rules.required]"
                color="deep-purple"
                label="*Inference Engine Release"
                outlined
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-checkbox
                v-model="inferenceVersionPayload.is_latest"
                label="Latest Engine Version"
                color="primary"
                hide-details="auto"
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="newInferenceVersion()"
              :disabled="!form || loading"
              color="primary"
            >
              SAVE
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'clientVersionList',
      'clientVersionTableLoading',
      'x86CordatusClients'
    ])
  },
  props: {
    showNewInferenceVersionDialog: { type: Boolean, default: false }
  },
  data: () => ({
    loading: false,
    form: true,
    newClientVersionId: null,
    inferenceVersionPayload: {
      name: '',
      is_latest: true
    },
    rules: {
      length: (len) => (v) =>
        (v || '').length <= len || `Name length can max ${len}.`,
      required: (v) => !!v || 'This field is required',
      numberRule: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true
        return 'Number has to be between 0 and 9'
      }
    }
  }),
  mounted () {
    if (this.clientVersionList.length === 0) {
      this.$store.dispatch('getClientVersion')
    }
  },

  methods: {
    async newInferenceVersion () {
      if (this.$refs.newInferenceEngineVersionForm.validate()) {
        this.loading = true
        await apiService
          .post('admin/inference_engine_version', this.inferenceVersionPayload)
          .then((res) => {
            this.loading = false
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'New client version added successfully'
            })
            this.$emit('added-new-inference-version')
            this.close()
          })
          .catch((err) => {
            this.loading = false
            Swal.fire({
              icon: 'error',
              text: `${err.response.data.message}`
            })
          })
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

  <style>
</style>
