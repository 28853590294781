<template>
  <div>
    <v-row>
      <v-col cols="6">
        <v-card>
          <v-card-title color="rgba(0, 0, 0, 0)" flat>
            <span class="font-weight-regular">Inference Engine Versions</span>
            <v-divider class="ml-2 mr-2" vertical></v-divider>
            <v-btn @click="engineReleaseList" icon>
              <v-icon dark>mdi-sync</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="mr-2"
              clearable
              dense
              hide-details
              label="Search inference engine release.."
              outlined
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
            <v-btn
              @click="newInferenceVersionDialog = true"
              outlined
              color="primary"
            >
              <v-icon left> mdi-plus </v-icon>
              New Version
            </v-btn>
          </v-card-title>

          <v-card-text class="mb-0 pb-0 mx-0 px-0">
            <v-data-table
              :loading="inferenceReleaseTableLoading"
              :search="search"
              :headers="headersEngines"
              :items="inferenceReleaseList"
              mobile-breakpoint="0"
              ref="devicesTable"
              sort-desc
              :items-per-page="20"
              :footer-props="{
                'items-per-page-options': [10, 20, 40, -1],
              }"
            >
              <template v-slot:[`item.is_latest`]="{ item }">
                <v-icon :color="item.is_latest ? 'green' : 'red'">{{
                  item.is_latest ? 'mdi-check' : 'mdi-minus'
                }}</v-icon>
              </template>

              <template v-slot:[`item.owner_info`]="{ item }">
                <v-chip
                  small
                  label
                  :color="
                    item.owner_info.email ? 'secondary' : 'grey lighten-1'
                  "
                  dark
                >
                  {{
                    item.owner_info.email
                      ? item.owner_info.email
                      : 'No information'
                  }}
                </v-chip>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <v-menu
                  transition="scale-transition"
                  bottom
                  offset-x
                  offset-overflow
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-horizontal-circle-outline</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-list-item link @click="listVersionUrlList(item.id)">
                      <v-list-item-icon class="mr-2">
                        <v-icon color="secondary">mdi-tag-arrow-right</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Tags List</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item :disabled="item.is_latest" link @click="selectLastVersion(item.id)">
                      <v-list-item-icon class="mr-2">
                        <v-icon :disabled="item.is_latest" color="primary">mdi-history</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title
                          >Select as latest release</v-list-item-title
                        >
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item :disabled="item.is_latest" link @click="deleteEngineRelease(item)">
                      <v-list-item-icon class="mr-2">
                        <v-icon :disabled="item.is_latest" color="red">mdi-delete</v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>Delete release</v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </v-card>
                </v-menu>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col cols="6">
        <v-card>
          <v-card-title color="rgba(0, 0, 0, 0)" flat>
            <span class="font-weight-regular">{{
              selectedInferenceEngine?.name
            }}</span>
            <v-divider class="ml-2 mr-2" vertical></v-divider>
            <v-btn @click="listVersionUrlList(selectedInferenceEngine?.id)" icon>
              <v-icon dark>mdi-sync</v-icon>
            </v-btn>
            <v-spacer></v-spacer>
            <v-text-field
              v-model="search"
              class="mr-2"
              clearable
              dense
              hide-details
              label="Search inference engine tags.."
              outlined
              prepend-inner-icon="mdi-magnify"
            ></v-text-field>
          </v-card-title>

          <v-card-text class="mb-0 pb-0 mx-0 px-0">
            <v-data-table
              :loading="inferenceEngineTagTableLoading"
              :search="search"
              :headers="headersEngineTags"
              :items="inferenceEngineTags"
              mobile-breakpoint="0"
              ref="devicesTable"
              sort-desc
              :items-per-page="30"
              :footer-props="{
                'items-per-page-options': [10, 30, 50, -1],
              }"
            >
              <template v-slot:[`item.is_latest`]="{ item }">
                <v-icon :color="item.is_latest ? 'green' : 'red'">{{
                  item.is_latest ? 'mdi-check' : 'mdi-minus'
                }}</v-icon>
              </template>

              <template v-slot:[`item.uncompressed_size`]="{ item }">
                <v-row class="d-flex align-center">
                  <v-col cols="6">
                    <v-text-field
                      v-if="isEditing && item.id === selectedDsTag.id"
                      v-model="item.uncompressed_size"
                      color="white"
                      :label="item.name"
                      class="inputNumber"
                      type="number"
                    ></v-text-field>
                    <span v-else>{{
                      formatBytes(item.uncompressed_size)
                    }}</span>
                  </v-col>
                  <v-col cols="4">
                    <v-btn
                      v-if="!isEditing || item.id !== selectedDsTag.id"
                      @click="setUncompressedSize(item)"
                      icon
                    >
                      <v-icon small>mdi-circle-edit-outline </v-icon>
                    </v-btn>

                    <v-btn
                      v-if="isEditing && item.id === selectedDsTag.id"
                      @click="editUncompressedSize(item)"
                      icon
                    >
                      <v-icon color="green">mdi-check-circle </v-icon>
                    </v-btn>

                    <v-btn
                      v-if="isEditing && item.id === selectedDsTag.id"
                      @click="cancelEditUncompressedSize()"
                      icon
                    >
                      <v-icon color="red">mdi-close-circle </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </template>
            </v-data-table>
          </v-card-text>

          <v-snackbar v-model="hasSaved" :timeout="2000" bottom right>
            Uncompressed size of the tag ID:{{ selectedDsTag.id }} has been
            edited.
          </v-snackbar>
        </v-card>

        <inference-engine></inference-engine>

        <new-inference-engine-version
          v-if="newInferenceVersionDialog"
          :showNewInferenceVersionDialog="newInferenceVersionDialog"
          @close="newInferenceVersionDialog = false"
          @added-new-inference-version="engineReleaseList"
        ></new-inference-engine-version>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { apiService } from '../../../services/api.service'
import converter from '@/mixins/converter'
import get from 'lodash/get'
import Swal from 'sweetalert2'
import NewInferenceEngineVersion from './NewInferenceEngineVersion.vue'
import InferenceEngine from './DeepstreamEngine.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'inferenceEngineVersion',
  computed: {
    ...mapGetters([
      'inferenceReleaseList',
      'inferenceReleaseTableLoading',
      'inferenceEngineTags',
      'inferenceEngineTagTableLoading',
      'selectedInferenceEngine'
    ])
  },
  components: { NewInferenceEngineVersion, InferenceEngine },
  data () {
    return {
      search: '',
      newInferenceVersionDialog: false,
      hasSaved: false,
      selectedDsTag: {},
      isEditing: false,
      headersEngines: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Is Latest', value: 'is_latest' },
        { text: 'Owner Information', value: 'owner_info' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      headersEngineTags: [
        { text: 'ID', value: 'id' },
        { text: 'Url', value: 'url' },
        { text: 'Uncompressed Size', value: 'uncompressed_size' },
        { text: 'Engine Release Id', value: 'engine_release_id' }
      ]
    }
  },
  mixins: [converter],
  created () {
    this.engineReleaseList()
  },
  methods: {
    async engineReleaseList () {
      this.$store.dispatch('getInferenceReleaseList')
    },
    async listVersionUrlList (releaseId) {
      this.$store.dispatch('getInferenceEngineTags', releaseId)
    },
    async selectLastVersion (versionId) {
      Swal.fire({
        title: 'Are you sure?',
        text: 'This engine will be last version.',
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await apiService
            .put(`admin/inference_engine_version/tags/${versionId}`)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Latest Version',
                text: 'Successfully change latest engine version.'
              })
              this.engineReleaseList()
              this.$store.dispatch('getInferenceEngineTags', versionId)
            })
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'Error while set latest the engine version',
                text: get(err, 'response.data.message', 'Something went wrong')
              })
            })
        }
      })
    },
    setUncompressedSize (item) {
      this.isEditing = true
      this.selectedDsTag = item
    },
    async editUncompressedSize (item) {
      await apiService
        .put(
          `admin/deepstream_engine_version/tag/${item.id}/${this.selectedDsTag.uncompressed_size}`
        )
        .then((res) => {
          this.hasSaved = true
          this.cancelEditUncompressedSize(item)
        })
        .catch((err) => {
          Swal.fire({
            icon: 'error',
            title: 'Failed get supported os list.',
            text: err.response.data.message
          })
        })
    },
    cancelEditUncompressedSize (item) {
      this.isEditing = false
      this.selectedDsTag = {}
      this.listVersionUrlList(item.engine_release_id)
    },
    async deleteEngineRelease (item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `This "${item.name}" release engine will be delete.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await apiService
            .delete(`admin/inference_engine_version/tags/${item.id}`)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Latest Version',
                text: 'Release engine successfully deleted!'
              })
              this.engineReleaseList()
            })
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'Error while deleted release engine.',
                text: get(err, 'response.data.message', 'Something went wrong')
              })
            })
        }
      })
    }
  }
}
</script>

<style>
.inputNumber input[type='number'] {
  -moz-appearance: textfield;
}

.inputNumber input::-webkit-outer-spin-button,
.inputNumber input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
</style>
