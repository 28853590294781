<template>
  <v-card class="mt-2">
    <v-card-title color="rgba(0, 0, 0, 0)" flat>
      <span class="font-weight-regular">Engine Docker Image Tags</span>
      <v-divider class="ml-2 mr-2" vertical></v-divider>
      <v-btn @click="deepstreamEngineList()" icon>
        <v-icon dark>mdi-sync</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        class="mr-2"
        clearable
        dense
        hide-details
        label="Search engine docker image tags.."
        outlined
        prepend-inner-icon="mdi-magnify"
      ></v-text-field>

      <v-btn @click="newDeepstreamEngineDialog = true" outlined color="primary">
        <v-icon left> mdi-plus </v-icon>
        New Engine
      </v-btn>
    </v-card-title>

    <v-card-text class="mb-0 pb-0 mx-0 px-0">
      <v-data-table
        :loading="deepstreamEngineLoading"
        :search="search"
        :headers="headersEngines"
        :items="deepstreamEngines"
        mobile-breakpoint="0"
        ref="devicesTable"
        sort-desc
        :items-per-page="30"
        :footer-props="{
          'items-per-page-options': [10, 30, 50, -1],
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <div class="d-flex flex-row">
            <v-btn
              v-for="(action, idx) in actionButtons"
              :key="idx"
              icon
              @click="action.func(item)"
            >
              <v-icon :color="action.color || 'black'">{{
                action.icon
              }}</v-icon>
            </v-btn>
          </div>
        </template>
      </v-data-table>
    </v-card-text>

    <new-deepstream-engine
      v-if="newDeepstreamEngineDialog"
      :showDeepstreamEngine="newDeepstreamEngineDialog"
      @close="newDeepstreamEngineDialog = false"
      @fetch-deepstream-engine-list="deepstreamEngineList"
    ></new-deepstream-engine>

    <edit-deepstream-engine
      v-if="showEditDeepstreamEngine"
      :showEditDeepstreamEngine="showEditDeepstreamEngine"
      :deepstreamEngine="selectedDeepstreamEngine"
      @close="showEditDeepstreamEngine = false"
      @fetch-deepstream-engine-list="deepstreamEngineList"
    ></edit-deepstream-engine>
  </v-card>
</template>

<script>
import { apiService } from '../../../services/api.service'
import get from 'lodash/get'
import Swal from 'sweetalert2'
import NewDeepstreamEngine from './NewDeepstreamEngine.vue'
import EditDeepstreamEngine from './EditDeepstreamEngine.vue'
export default {
  components: { NewDeepstreamEngine, EditDeepstreamEngine },
  data () {
    return {
      search: '',
      deepstreamEngines: [],
      deepstreamEngineLoading: [],
      showEditDeepstreamEngine: false,
      selectedDeepstreamEngine: {},
      newDeepstreamEngineDialog: false,
      headersEngines: [
        { text: 'ID', value: 'id' },
        { text: 'Name', value: 'name' },
        { text: 'Driver Min', value: 'driver_min' },
        { text: 'Driver Max', value: 'driver_max' },
        { text: 'Jetpack Min', value: 'jp_min' },
        { text: 'Jetpack Max', value: 'jp_max' },
        { text: 'Jetpack Name', value: 'jp_name' },
        { text: 'Actions', value: 'actions' }
      ],
      actionButtons: [
        {
          icon: 'mdi-circle-edit-outline',
          color: 'grey',
          func: this.editDeepstreamEngineVersion
        },
        {
          icon: 'mdi-delete-outline',
          color: 'red',
          func: this.deleteDeepstreamEngineVersion
        }
      ]
    }
  },
  created () {
    this.deepstreamEngineList()
  },
  methods: {
    async deepstreamEngineList () {
      this.deepstreamEngineLoading = true
      await apiService
        .get('admin/deepstream_engines')
        .then((res) => {
          this.deepstreamEngines = res.data.data
          this.deepstreamEngineLoading = false
        })
        .catch((err) => {
          this.deepstreamEngineLoading = false
          Swal.fire({
            icon: 'error',
            title: 'Error getting deepstream engine version list',
            text: get(err, 'response.data.message', 'Something went wrong')
          })
        })
        .finally(() => {
          this.deepstreamEngineLoading = false
        })
    },
    editDeepstreamEngineVersion (item) {
      this.selectedDeepstreamEngine = item
      this.showEditDeepstreamEngine = true
    },
    deleteDeepstreamEngineVersion (item) {
      Swal.fire({
        title: 'Are you sure?',
        text: `This "${item.version}" deepstream engine version will be delete.`,
        showCancelButton: true,
        confirmButtonText: 'Yes, do it!'
      }).then(async (result) => {
        if (result.isConfirmed) {
          await apiService
            .delete(`admin/deepstream_engines/${item.id}`)
            .then(() => {
              Swal.fire({
                icon: 'success',
                title: 'Deleted',
                text: 'Deepstream engine version deleted successfully!'
              })
              this.deepstreamEngineList()
            })
            .catch((err) => {
              Swal.fire({
                icon: 'error',
                title: 'Error while deleted deepstream engine version.',
                text: get(err, 'response.data.message', 'Something went wrong')
              })
            })
        }
      })
    }
  }
}
</script>

<style>
</style>
