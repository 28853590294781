<template>
  <v-dialog persistent v-model="showDeepstreamEngine" width="700">
    <v-card :loading="loading">
      <v-form
        lazy-validation
        v-model="form"
        ref="newDeepstreamEngineForm"
        :disable="loading"
      >
        <v-card-title>
          <span class="text-h6">New Engine Docker Image Tag</span>
          <v-spacer></v-spacer>
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="deepstreamEnginePayload.name"
                :rules="[rules.required]"
                color="deep-purple"
                label="*DeepStream Version Naming"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="deepstreamEnginePayload.driver_min"
                :rules="[rules.required]"
                color="deep-purple"
                label="*Minimum NVIDIA Driver Version"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="deepstreamEnginePayload.driver_max"
                :rules="[rules.required]"
                color="deep-purple"
                label="*Maximum NVIDIA Driver Version"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="deepstreamEnginePayload.jp_min"
                hide-details="auto"
                label="*Minimum Jetpack Version"
                outlined
                :items="jetpackVersionList"
                :rules="[rules.required]"
                :item-text="
                  (item) =>
                    'L4T: ' +
                    item.l4t +
                    ' / ' +
                    'Jetpack Version: ' +
                    item.version
                "
                item-value="version"
              >
                <template v-slot:selection="{ item }">
                  <v-chip :color="item.color" small>{{
                    'Jetpack: ' + item.version
                  }}</v-chip>
                </template>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          <span>{{ 'Jetpack: ' + item.version }}</span>
                          <v-spacer></v-spacer>
                          <v-chip small color="primary">{{
                            'L4T: ' + item.l4t
                          }}</v-chip>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col cols="6">
              <v-select
                v-model="deepstreamEnginePayload.jp_max"
                hide-details="auto"
                label="*Maximum Jetpack Version"
                outlined
                :items="jetpackVersionList"
                :rules="[rules.required]"
                :item-text="
                  (item) =>
                    'L4T: ' +
                    item.l4t +
                    ' / ' +
                    'Jetpack Version: ' +
                    item.version
                "
                item-value="version"
              >
                <template v-slot:selection="{ item }">
                  <v-chip :color="item.color" small>{{
                    'Jetpack: ' + item.version
                  }}</v-chip>
                </template>
                <template v-slot:item="{ item, attrs, on }">
                  <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                    <v-list-item-action>
                      <v-checkbox :input-value="active"></v-checkbox>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-row no-gutters align="center">
                          <span>{{ 'Jetpack: ' + item.version }}</span>
                          <v-spacer></v-spacer>
                          <v-chip small color="primary">{{
                            'L4T: ' + item.l4t
                          }}</v-chip>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12">
              <v-text-field
                v-model="deepstreamEnginePayload.jp_name"
                :rules="[rules.required]"
                color="deep-purple"
                label="*NVIDIA JetPack Version Naming"
                outlined
                hide-details="auto"
              ></v-text-field>
            </v-col>

            <v-col cols="6">
              <v-autocomplete
                disabled
                v-model="deepstreamEnginePayload.min_x86_client_version_id"
                item-text="version"
                item-value="id"
                auto-select-first
                label="*Minimum Client(x86) Version"
                outlined
                clearable
                deletable-chips
              ></v-autocomplete>
            </v-col>

            <v-col cols="6">
              <v-autocomplete
                disabled
                v-model="deepstreamEnginePayload.min_jetson_client_version_id"
                item-text="version"
                item-value="id"
                auto-select-first
                label="*Minimum Client(Jetson) Version"
                outlined
                clearable
                deletable-chips
              ></v-autocomplete>
            </v-col>

            <v-col cols="12">
              <v-alert
                border="left"
                text
                type="info"
                class="pb-0"
                transition="fade-transition"
              >
                <span class="font-weight-medium">Docker image tags:</span>
                <p class="tagFontSize">
                  x86:
                  <span class="black--text"
                    >{{
                      harborUrl
                    }}/cordatus/cordatus-inference-engine:x86-</span
                  >
                  <span
                    :class="
                      deepstreamEnginePayload.name
                        ? 'black--text'
                        : 'green--text'
                    "
                    >{{
                      deepstreamEnginePayload.name
                        ? deepstreamEnginePayload.name
                        : '.deepstream_engine_name'
                    }}</span
                  >
                  <span class="black--text">-v</span>
                  <span class="orange--text">{{
                    selectedInferenceEngine.name
                  }}</span>
                </p>
                <p class="tagFontSize">
                  arm64:
                  <span class="black--text"
                    >{{ harborUrl }}/cordatus/cordatus-inference-engine:</span
                  >
                  <span
                    :class="
                      deepstreamEnginePayload.jp_name
                        ? 'black--text'
                        : 'green--text'
                    "
                    >{{
                      deepstreamEnginePayload.jp_name
                        ? deepstreamEnginePayload.jp_name
                        : '.jp_name'
                    }}</span
                  >
                  <span class="black--text">-</span>
                  <span
                    :class="
                      deepstreamEnginePayload.name
                        ? 'black--text'
                        : 'green--text'
                    "
                    >{{
                      deepstreamEnginePayload.name
                        ? deepstreamEnginePayload.name
                        : 'deepstream_engine_name'
                    }}</span
                  >
                  <span class="black--text">-v</span>

                  <span class="orange--text">{{
                    selectedInferenceEngine.name
                  }}</span>
                </p>
              </v-alert>
            </v-col>
          </v-row>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              @click="newDeepstreamEngine()"
              :disabled="!form || loading"
              color="primary"
            >
              SAVE
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import Swal from 'sweetalert2'
import { apiService } from '../../../services/api.service'
import { mapGetters } from 'vuex'
import environment from '../../../environment'
export default {
  computed: {
    ...mapGetters(['jetpackVersionList', 'selectedInferenceEngine']),
    harborUrl: {
      get () {
        return environment.harborUrl
      }
    }
  },
  props: {
    showDeepstreamEngine: { type: Boolean, default: false }
  },
  data: () => ({
    loading: false,
    form: true,
    deepstreamEnginePayload: {
      name: '',
      driver_min: null,
      driver_max: null,
      jp_min: null,
      jp_max: null,
      jp_name: null
    },
    rules: {
      length: (len) => (v) =>
        (v || '').length <= len || `Name length can max ${len}.`,
      required: (v) => !!v || 'This field is required',
      numberRule: (v) => {
        if (!isNaN(parseFloat(v)) && v >= 0) return true
        return 'Number has to be between 0 and 9'
      }
    }
  }),
  created () {
    this.getVersionList()
  },
  methods: {
    async newDeepstreamEngine () {
      if (this.$refs.newDeepstreamEngineForm.validate()) {
        this.loading = true
        await apiService
          .post('admin/deepstream_engines', this.deepstreamEnginePayload)
          .then((res) => {
            this.loading = false
            Swal.fire({
              icon: 'success',
              title: 'Success',
              text: 'New deepstream engine added successfully'
            })
            this.$emit('fetch-deepstream-engine-list')
            this.close()
          })
          .catch((err) => {
            this.loading = false
            Swal.fire({
              icon: 'error',
              text: `Error: ${err.response.data.message}`
            })
          })
      }
    },

    getVersionList () {
      if (this.jetpackVersionList.length === 0) {
        this.$store.dispatch('getJetpackVersionList')
      }
    },
    close () {
      this.$emit('close')
    }
  }
}
</script>

  <style>
.tagFontSize {
  font-size: 14px;
}
</style>
